.header-filter {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colors-secondary-xxxsoft, #d4d4de);

  &__desktop {
    border-bottom: none;
    // margin-left: 16px;
    height: auto;
  }

  &__app-mobile {
    height: 52px;
  }

  &__title-desktop {
    width: 100%;
    margin-left: 8px;
  }

  &__icon {
    &-close {
      background: none;
      border: none;
      right: 17px;
      line-height: 0 !important;
      padding: 4px;
      position: absolute;
      cursor: pointer;

      > * {
        margin-bottom: 0;
      }

      &:hover,
      &:focus {
        transform: scale(1.1, 1.1);
      }

      &:focus {
        box-shadow: 0 1px 6px rgb(0 0 0 / 17%);
      }

      &:active {
        transform: scale(1, 1);
      }
    }
  }
}
