@import '../../responsive-nlc';

.checkbox-group {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include respond-to-large-tablet {
    &-two-columns {
      flex-wrap: wrap;
      row-gap: 16px;

      > div {
        width: 49%;
      }
    }
  }

  &__divider {
    border-top: 1px solid var(--container-secondary-with-menu-border-default, #d4d4de);
    margin: 0 16px;
  }

  &__seeAll {
    text-align: center;
    height: 40px;
  }
}

.checkbox-group:not(.checkbox-group-two-columns) {
  flex-direction: column;

  .checkbox-group__withoutIcon {
    min-height: 32px;
    align-items: center;
  }
}
