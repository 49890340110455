.select-list {
  max-height: 195px;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: var(--lcui-greyLight);
    border-radius: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--lcui-greyMedium);
    border-radius: 5px;
  }
}

.crossMini {
  position: absolute;
  top: 13px;
  right: 50px;
  z-index: 100;
}
