$desktop-min-size: 1200px;
$tablet-min-size: 970px;
$mobile-min-size: 615px;

// <= 615px
@mixin respond-to-mobile() {
  @media only screen and (max-width: $mobile-min-size) {
    @content;
  }
}

// > 616px
@mixin not-mobile() {
  @media only screen and (min-width: ($mobile-min-size + 1)) {
    @content;
  }
}

// <= 969px
@mixin respond-to-tablet() {
  @media only screen and (max-width: ($tablet-min-size - 1)) {
    @content;
  }
}

// >= 970px
@mixin respond-to-large-tablet() {
  @media only screen and (min-width: $tablet-min-size) {
    @content;
  }
}

// [970px;1199px]
@mixin respond-to-desktop-and-tablet() {
  @media only screen and (min-width: $tablet-min-size) and (max-width: ($desktop-min-size - 1)) {
    @content;
  }
}

// < 1200px
@mixin not-desktop() {
  @media only screen and (max-width: ($desktop-min-size - 1)) {
    @content;
  }
}

// > 1200px
@mixin respond-to-desktop() {
  @media only screen and (min-width: $desktop-min-size) {
    @content;
  }
}
