@import '../../responsive-nlc';

.filter-modal {
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 2000;
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: var(--lcui-white);

  &__divider {
    margin-top: -1px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__button {
    &-content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      padding: 12px 16px;
      gap: var(--spacing-medium, 16px);
      background: var(--colors-negative, #fff);
      box-shadow: 0 -1px 4px 0 var(--colors-elevation-1, rgb(109 109 131 / 25%));
    }

    &-cancel {
      width: 50%;
    }

    &-validate {
      width: 50%;
      white-space: nowrap;

      &.disabled {
        pointer-events: none;
        text-align: left;
        white-space: break-spaces;
      }
    }
  }

  &__input-content {
    width: 100%;
    padding: 10px 16px;
    gap: 11px;
    font-weight: 400;
    font-size: 13px;
    // max-height: calc(100vh - 156px);
    overflow: auto;

    .checkbox-group__withoutIcon {
      padding: 0 !important;
    }

    label {
      text-align: left;
    }

    &-localization {
      display: block;
      padding: 7px 15px;

      .Controls_Controls_controls {
        margin-top: 32px;
      }
    }

    .searchList {
      width: 100%;
    }
  }
}

.button {
  display: flex;
  margin: 9px 0 0;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  position: relative;

  &__placeholder {
    cursor: default;
    transform: translateY(-19px);
    z-index: 0;
    padding: 0 3px;
    position: absolute;
    top: 12px;
    left: 5px;
    background: var(--lcui-white);
  }
}
