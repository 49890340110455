@import '../../responsive-nlc';

.dropdownHomePage__container {
  position: relative;
  font-size: 14px;
  width: 100%;

  .label {
    z-index: 0;
    padding: 0 3px;
    position: absolute;
    left: 5px;
    transition: transform 300ms;
    cursor: pointer;
    top: 15px;

    &__desktop {
      top: 13px;
      display: flex;
      gap: 8px;
    }
  }

  &[data-has-value] .label {
    cursor: default;
    transform: translateY(-12px);
    z-index: 0;

    &__desktop {
      transform: translateY(-19px);
      background-color: var(--lcui-white);
      z-index: 4;
    }
  }

  .collapsible {
    border-radius: 8px;

    .separator {
      margin: 0;
      width: auto;
    }

    .trigger {
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 12px 8px;
      position: relative;
      user-select: none;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      @include respond-to-tablet {
        height: 50px;
      }

      @include respond-to-large-tablet {
        &__desktop {
          height: 40px;

          &::after {
            content: '';
            mask: url('https://www.lacentrale.fr/static/fragment-lc-mozart-commons/statics/icons/chevron.svg')
              no-repeat 55% 55%;
            -webkit-mask: url('https://www.lacentrale.fr/static/fragment-lc-mozart-commons/statics/icons/chevron.svg')
              no-repeat 55% 55%;
            background-color: var(--component-dropdown-chevron-default, #6d6d83);
            height: 16px;
            width: 16px;
            transition: transform 150ms linear;
          }
        }
      }

      .value {
        max-width: 131px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 1px 0;
        display: flex;
        gap: 8px;

        &.isCategoryVehicle {
          padding-left: 30px;
        }
      }
    }

    .triggerOpenedDesktop {
      border-radius: 8px;
      border: 1px solid var(--component-dropdown-border-active, #2a292f);

      &.isError {
        border-color: var(--component-dropdown-border-error, #ff004f);
      }
    }

    .triggerClosedDesktop {
      border-radius: 8px;
      border: 1px solid var(--component-dropdown-border-default, #d4d4de);

      &.isError {
        border-color: var(--component-dropdown-border-error, #ff004f);
      }
    }

    .triggerCompletedClosed,
    .triggerCompletedOpen {
      justify-content: space-between;
    }

    .triggerClosed::after {
      transform: rotate(270deg);
    }

    .triggerOpened::after {
      transform: rotate(0.25turn);
    }

    .contentOuter {
      border-radius: 8px;
      width: 300px;
      margin-top: 4px;
      box-shadow: 0 1px 9px rgb(98 129 247 / 20%);

      &__desktop {
        position: absolute;
        z-index: 5;
        margin-top: 8px;

        &__opened {
          border: 1px solid var(--component-dropdown-border-active, #2a292f);

          &.isError {
            border: 1px solid var(--component-dropdown-border-error, #ff004f);
          }
        }
      }
    }

    .contentInner {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding: 16px;
      background: var(--lcui-white);
      text-align: left;

      .input-min-max-filter {
        margin: 0;
      }
    }
  }
}
