.sellField {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;

  .divider {
    width: 100%;
    height: 20px;

    &__text {
      transform: translate(-50%, -90%);
      margin-left: 50%;
      padding: 0 10px;
      background-color: var(--lcui-white);
      width: fit-content;
    }
  }
}
