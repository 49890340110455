@import '../../responsive-nlc';

.mobile-filter-entry {
  &-web {
    display: none;

    @include respond-to-tablet {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 8px;
      white-space: nowrap;
      border: 1px solid var(--lcui-greyLight);
      height: 40px;
      padding: 10px 8px;
      width: calc(50% - 4px);
      max-width: 50vw;
    }
  }

  &.takeFullWidth {
    width: 100%;
    max-width: 100%;
  }

  &--category-filter {
    overflow: hidden;
    text-overflow: ellipsis;

    .label-with-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
  }

  &-app {
    background-color: var(--lcui-white);
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    white-space: nowrap;
    border: 1px solid var(--lcui-greyLight);
    height: 40px;
    padding: 10px 8px;
    width: calc(50% - 4px);
    max-width: 50vw;
  }
}
