@import '../../responsive-nlc';

.searchList {
  display: flex;
  flex-direction: column;

  .dropdown {
    &__items-separator {
      margin: 8px;
      width: 98%;
    }

    &__search-input {
      -webkit-appearance: none; // to hide default left icon on mobile ios
      background: transparent none; // to hide default background on mobile ios
      width: 100%;
      box-sizing: border-box;
      border: 1px solid var(--component-textfield-border-default, #d4d4de);
      border-radius: 5px;
      height: 40px;
      padding-left: 25px;
      background-image: url('./picto/search.svg');
      background-repeat: no-repeat;
      background-position: 5px;
      color: var(--component-textfield-text-completed, #2a292f);

      &.modal-home {
        font-size: 14px;
      }

      &::placeholder {
        font-size: 14px;
        color: var(--component-textfield-label-default, #6d6d83);
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url('./picto/cross.svg');
        background-size: 100%;
        background-repeat: no-repeat;
      }

      &[disabled] {
        background-image: url('./picto/disabledSearch.svg');
        border: 1px solid var(--component-textfield-border-disabled, #d4d4de);

        &::placeholder {
          font-size: 14px;
          color: 1px solid var(--component-textfield-label-disabled, #c0c0ce);
        }
      }

      &:not([disabled]):hover,
      &:not([disabled]):focus,
      &-focus:not([disabled]) {
        border-color: var(--component-textfield-border-hover, #2a292f);
        outline: none;
      }

      &--equipment-option {
        margin-bottom: 12px;
        background-image: none;
        padding-left: 8px;
      }
    }

    &__input-container {
      width: 100%;
      margin-bottom: 8px;
    }

    &__wrapper {
      &__brand-name {
        text-align: left;
        margin: 6px 0 2px 8px;
        font-size: 14px;
        font-weight: 600;
      }

      &__shortItem {
        display: flex;
      }
    }

    &__placeholder {
      text-align: left;
    }

    &__wrapper > div:nth-child(1) {
      &::-webkit-scrollbar {
        background-color: var(--component-dropdown-border-default, #d4d4de);
        border-radius: 4px;
        width: 3px;
        height: 124px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--colors-secondary-xsoft, #a6a6ba);
        border-radius: 4px;
      }
    }
  }

  &__filterTagsContainer {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 12px;
    scrollbar-color: var(--lcui-greyMedium) var(--lcui-greyLight);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: var(--lcui-greyLight);
      border-radius: 4px;
      width: 3px;
      height: 124px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lcui-greyMedium);
      border-radius: 4px;
    }

    > .Tag_Tag_clickable,
    .Text_Text_text.Text_Text_bold {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__seeMoreFilterTags {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 12px;
  }
}
