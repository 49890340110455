.multiSelectTags {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 16px 0;

    &-exclusive {
      flex-wrap: nowrap;

      .multiSelectTags__button {
        flex-basis: 49%;
        flex-shrink: 0;
      }
    }
  }

  &__item-label {
    font-weight: normal;
  }

  &__seeAll {
    padding-left: 4px;
  }
}
