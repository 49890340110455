.select-consommation {
  z-index: 3;
}

.dimension-filter {
  display: flex;
  gap: 8px;

  &__input {
    width: 100%;
  }
}

.dimension-title {
  margin-bottom: 10px;
}

.localisation__container {
  margin: 16px 0;
}

.customerTypeCategoryBox {
  > .category-box__content {
    display: block;
    padding: 16px;
  }

  > .category-box__content > button {
    width: 48%;
  }

  .customerInfoButtonLeft {
    margin-right: 2%;
  }

  .customerInfoButtonRight {
    margin-left: 2%;
  }
}
