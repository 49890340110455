@import '../../../responsive-nlc';

.grid__filters {
  &:not(.grid__filters__localisation) {
    width: calc(50% - 8px);

    @include respond-to-tablet() {
      width: calc(50% - 4px);
      max-width: 50vw;
    }
  }

  &__localisation {
    width: 100%;

    .Collapsible__contentOuter {
      width: 360px !important;
    }
  }

  &--text {
    position: absolute;
    left: 32px;
  }
}

.mobile {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  position: relative;

  @include respond-to-tablet {
    gap: 16px;
  }

  &__moreCriterias-web {
    @include respond-to-large-tablet {
      display: none;
    }
  }

  &__filters-content {
    top: 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    @include respond-to-tablet {
      gap: 8px;
    }

    &-app {
      gap: 8px;
    }

    .emptyDiv {
      visibility: hidden;

      @include respond-to-tablet {
        display: none;
      }
    }

    .financingDesktop {
      width: 100%;
      margin-top: -16px;

      @include respond-to-tablet {
        display: none;
      }

      .input-min-max-filter {
        column-gap: 16px;
      }
    }

    .filter-modal {
      .radio-button {
        margin: 15px 0;
      }

      &-web {
        @include respond-to-large-tablet {
          display: none;
        }
      }
    }

    .dropdown-home {
      display: block;

      @include respond-to-tablet {
        display: none;
      }

      @include respond-to-large-tablet {
        &:nth-child(odd) {
          .Collapsible__contentOuter {
            left: 0;
          }
        }

        &:nth-child(even) {
          .Collapsible__contentOuter {
            right: 0;
          }
        }
      }

      .Collapsible__trigger {
        text-align: left;
      }

      .Collapsible__contentOuter {
        width: 300px;
        box-shadow: 0 1px 9px rgb(98 129 247 / 20%);
        position: absolute;
        z-index: 5;
        margin-top: 8px;
        background-color: var(--lcui-white);
      }

      .Dropdown_Dropdown_triggerOpened + .Collapsible__contentOuter {
        border: 1px solid var(--component-dropdown-border-active, #2a292f);
      }

      .Dropdown_Dropdown_separator {
        display: none;
      }

      &-mobile {
        display: none;
      }
    }
  }

  &__separator {
    height: 36px;
    position: absolute;
    top: 7px;
    display: block;
    margin: 0 !important;
  }

  &__button {
    &-app {
      width: 100%;
    }
  }

  &__searchButton {
    min-width: 194px;
    height: 40px;

    &.disabled {
      pointer-events: none;
    }
  }

  &__modal__price {
    width: 270px;
    margin: auto;

    .Tabs_Tabs_roundedTab {
      margin-bottom: 16px;
    }
  }
}

.homeSearch .mobile {
  display: flex;
  margin: 9px 0 0;
}
