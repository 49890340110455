.filter-collapsible {
  display: flex;
  min-width: 406px;
  max-width: 440px;
  padding: 15px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border: 1px solid var(--component-dropdown-border-active, #2a292f) !important;
  background: var(--component-dropdown-bkg-active, #fff);
  box-shadow: 0 1px 4px 0 var(--colors-elevation-1, rgb(109 109 131 / 25%));
  border-radius: var(--radius-medium, 8px);

  &-error {
    border: 1px solid var(--component-dropdown-border-error, #ff004f) !important;
  }

  &__content {
    width: 100%;
  }
}
