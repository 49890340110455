.radio-button {
  display: flex;

  &.twoColumns {
    flex-wrap: wrap;

    > div {
      width: 50%;
      align-items: center;
      min-height: 32px;
    }
  }

  &.isFilterPage {
    flex-direction: column;

    > div {
      padding: var(--spacing-xsmall) 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.isListingPage {
    row-gap: 8px;
    flex-direction: column;
  }
}

.Radio_RadioButton_input:not([checked]) {
  border: 1px solid var(--lcui-greyMedium);
}

.Radio_RadioButton_input:not([checked])::before {
  transform: scale(0);
}
