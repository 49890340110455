.filter-collapsible-container {
  position: relative;
  box-shadow: 0 1px 4px 0 var(--colors-elevation-1, rgb(109 109 131 / 25%));
  border-radius: var(--radius-medium, 8px);

  .filter-collapsible-button
    &:has(.input-min-max-filter--error-message, .input-min-max-filter--error, .error) {
    color: var(--component-list-menu-label-error, #f84a4a) !important;
  }
}

.filter-collapsible-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--container-main-with-menu-bkg-default, #fff);
  color: var(--container-main-with-menu-title-default, #2a292f);
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;
  position: relative;

  .chevron path {
    fill: var(--container-main-with-menu-title-default, #2a292f);
  }

  &.error {
    border: 1px solid var(--container-main-with-menu-border-error, #f84a4a);
  }

  &.small {
    background-color: var(--container-main-with-menu-bkg-default, #fff);
    border: none !important;
    border-radius: 0 !important;
    height: 48px;
    margin: 0;

    .chevron path {
      fill: var(--component-list-menu-icon-default, #6d6d83);
    }

    &.active {
      outline: none;
      color: var(--container-main-with-menu-title-active, #ff004f);
      background: var(--component-list-menu-bkg-default, rgb(109 109 131 / 5%));

      .chevron path {
        fill: var(--container-main-with-menu-title-hover, #ff004f);
      }
    }

    &:hover {
      background: var(--component-list-menu-bkg-hover, rgb(109 109 131 / 5%));
      color: var(--component-list-menu-label-hover, #2a292f);
    }
  }

  &:hover:not(.small) {
    color: var(--container-main-with-menu-title-hover, #ff004f);

    .chevron path {
      fill: var(--container-main-with-menu-title-hover, #ff004f);
    }
  }

  &.active:not(.small) {
    color: var(--container-main-with-menu-title-active, #ff004f);
    outline: 1px solid var(--container-main-with-menu-border-active, #2a292f);

    .chevron path {
      fill: var(--container-main-with-menu-title-hover, #ff004f);
    }
  }
}

.count {
  position: absolute;
  right: 45px;
}

.collapsible-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsible-element {
  z-index: 12;
}

.collapsible-content {
  position: absolute;
  z-index: 15;
  left: 267px;
}

.filter-collapsible-button.small + .collapsible-overlay .collapsible-content {
  left: 266px;
}
