.input-min-max-filter {
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 16px 0;

  &--input {
    width: 100%;
  }

  &--padding-top {
    padding-top: 7px;
  }

  &--error {
    margin-bottom: 32px;

    &-home {
      margin-bottom: 12px;
    }

    &-message {
      position: absolute;
      top: 102%;
      left: 8px;
    }
  }
}

.filter-collapsible__content {
  .input-min-max-filter {
    margin: 0;
  }
}
