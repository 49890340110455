.filter-title {
  &__category {
    display: flex;
    align-items: center;
    height: 38px;
    font-weight: 600;
    padding-right: 16px;
  }

  &__withoutDivider-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-top: 8px;
  }

  &__icon {
    cursor: pointer;
  }

  &__expand {
    padding: 16px 0;
  }

  &__divider {
    margin: 0 !important;
  }

  &__filter-page {
    margin-top: 20px;
    margin-bottom: 16px;
  }
}
