.category-box {
  border-radius: var(--radius-medium, 8px);
  box-shadow: 0 1px 4px 0 var(--colors-elevation-1, rgb(109 109 131 / 25%));

  &__error {
    border-color: var(--lcui-danger); //TODO
  }

  &:has(.input-min-max-filter--error-message, .error) {
    outline: 1px solid var(--container-main-with-menu-border-error, #f84a4a);
  }

  &:has(.filter-collapsible-container) .filter-collapsible-container {
    box-shadow: inherit;
    border-radius: inherit;
  }

  &__title {
    background-color: var(--container-main-with-menu-bkg-default, #fff);
    border-radius: var(--radius-medium, 8px) var(--radius-medium, 8px) 0 0;
    border-bottom: 1px solid var(--container-secondary-with-menu-border-default, #d4d4de);
    padding: 8px 16px;
  }

  &__content {
    background-color: var(--container-main-with-menu-bkg-default, #fff);
    border-bottom-right-radius: var(--radius-medium, 8px);
    border-bottom-left-radius: var(--radius-medium, 8px);
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;

    .checkbox-group:not(.checkbox-group-two-columns) {
      flex-direction: column;

      .checkbox-group__withoutIcon {
        min-height: 40px;
        align-items: center;
        padding: 0 16px;
      }

      .checkbox-group__withIcon {
        min-height: 40px;
        align-items: center;
      }
    }

    &:last-child {
      .filter-collapsible-container:last-child > .filter-collapsible-button {
        border-bottom-left-radius: var(--radius-medium, 8px) !important;
        border-bottom-right-radius: var(--radius-medium, 8px) !important;
      }
    }
  }

  &__content
    > :not(
      .filter-collapsible-container,
      .Tabs_Tabs_tabs,
      .custom-padding,
      .radio-button,
      .multiSelectTags__button,
      .checkbox-tree,
      .checkbox-group
    ),
  &__content > .radio-button > div {
    padding: 0 16px;
    min-height: 40px;
    width: 100%;
  }

  &__content > .radio-button {
    padding: 0;
    gap: 0 !important;
  }

  &__content > .custom-padding {
    padding: 12px 16px;
  }

  &__content > .Tabs_Tabs_roundedTab {
    width: auto;
    margin: 16px 16px 0;
  }

  &__content .input-min-max-filter--error-message {
    position: absolute;
    top: 80%;
    left: 16px;
    padding: 8px 0;
  }

  &__content .collapsible-content .input-min-max-filter--error-message,
  &__content .filter-modal .input-min-max-filter--error-message {
    left: 8px;
    padding: 8px 0;
  }
}
