@import '../../../responsive-nlc';

.financingTypeBox {
  display: flex;
  justify-content: flex-start;
  gap: 11px;

  @include respond-to-tablet {
    padding-top: 26px;
  }
}

.financingTypeBox > div {
  width: fit-content;
}
